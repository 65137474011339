import React, { useState, SFC } from "react"
import { Link } from "gatsby"
interface Item {
  type: "anchor" | "link"
  target: string
  text: string
}
interface Props {
  data: {
    cta: Item
    links: Item[]
  }
  lang: string
}
export const Header: SFC<Props> = ({ data, lang }) => {
  const [isMenuOpen, setMenuOpen] = useState(false)
  return (
    <header className="bg-blue-800 fixed left-0 right-0 top-0 z-50">
      <nav className="flex items-center justify-between flex-wrap p-3 pr-5">
        <div className="flex items-center flex-shrink-0 text-white mr-6">
          <svg
            className="logo"
            style={{ width: "48px", height: "48px" }}
            viewBox="0 0 241 241"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M26.532 172.71L27.0374 106.127L146.431 166.961L119.393 220.025L26.532 172.71Z"
              className="logo-1"
            />
            <path
              d="M107.138 80.1009L107.391 46.8098L134.175 27.0373L213.771 67.5932L213.265 134.175L107.138 80.1009Z"
              className="logo-1"
            />
            <path
              d="M107.138 80.1009L173.468 113.897L213.771 67.5932L107.138 80.1009Z"
              className="logo-2"
            />
            <path
              d="M133.165 160.202L66.835 126.405L26.5318 172.71L133.165 160.202Z"
              className="logo-2"
            />
            <path
              d="M213.265 134.175L26.5318 172.71L27.0373 106.127L213.771 67.5932L213.265 134.175Z"
              className="logo-3"
            />
          </svg>

          <span className="font-semibold text-xl tracking-tight uppercase tracking-widest">
            ZapWerx
          </span>
        </div>
        <div className="block lg:hidden">
          <button
            onClick={() => {
              setMenuOpen((isMenuOpen: any) => !isMenuOpen)
            }}
            className="flex items-center px-3 py-2 text-blue-500 bg-blue-700 border-blue-600 border-2 hover:text-white hover:border-white"
          >
            <svg
              className="fill-current h-5 w-5"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>
        <div
          className={`w-full ${
            isMenuOpen ? "block" : "hidden"
          } lg:block flex-grow lg:flex lg:items-center lg:w-auto`}
        >
          <div className="text-sm lg:flex-grow text-center lg:text-left">
            {data.links.map(({ type, target, text }) => {
              switch (type) {
                case "anchor":
                  return (
                    <a
                      href={target}
                      className="block mt-2 lg:inline-block lg:mt-0 py-1 lg:py-0 text-blue-200 hover:text-white mr-4"
                    >
                      {text}
                    </a>
                  )
                case "link":
                  return <Link to={target}>{text}</Link>
                default:
                  return null
              }
            })}
          </div>
          <div className="text-center mt-5 lg:mt-0">
            <a
              href={data.cta.target}
              className="inline-block text-sm p-3 px-6 leading-none border-2 bg-green-500 rounded text-white text-bold border-green-600 hover:bg-green-600"
            >
              {data.cta.text}
            </a>
            <Link
              className="inline-block text-sm py-3 w-12 text-center leading-none border-2 uppercase bg-blue-500 rounded text-white border-blue-600 hover:bg-blue-600 ml-3"
              to={lang === "en" ? "/fr/" : "/en/"}
            >
              {lang === "en" ? "FR" : "EN"}
            </Link>
          </div>
        </div>
      </nav>
    </header>
  )
}
export default Header
