import React, { ReactElement } from "react"
import Header from "../components/Header"
import Service from "../components/Service"
import AboutUs from "../components/AboutUs"
import { ContactForm } from "../components/ContactForm"
import Footer from "../components/Footer"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import "../css/index.scss"

function DefaultTemplate({ data }): ReactElement {
  const page = data.page.edges[0].node.context
  return (
    <>
      <Helmet>
        <title>{page.title}</title>
        <meta name="description" content={page.description} />
      </Helmet>
      <Header data={page.content.navigation} lang={page.lang} />
      <div
        id="services"
        className="bg-gray-200 p-10 pt-0 pb-32"
        style={{ marginTop: "72px" }}
      >
        <div className="flex flex-wrap justify-center -mx-10">
          {page.content.services.map(
            ({ featured, title, getintouch, content, img }) => (
              <Service
                featured={featured}
                title={title}
                getintouch={getintouch}
                img={data[img].childImageSharp.fluid}
              >
                {content}
              </Service>
            )
          )}
        </div>
      </div>
      <div className="bg-blue-600 py-10">
        <div className="flex flex-col md:flex-row">
          <div className="md:w-1/3 p-10 md:order-2">
            <AboutUs id="about-us" title={page.content.about.title}>
              {page.content.about.content}
            </AboutUs>
          </div>
          <div id="contact" className="md:w-2/3 p-5 md:p-10 md:order-1">
            <div className="bg-gray-800 p-5 md:p-10">
              <h2 className="text-2xl font-bold text-white mb-6">
                {page.content.contact.form.title}
              </h2>

              <ContactForm data={page.content.contact.form} lang={page.lang} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export const query = graphql`
  query($path: String!) {
    page: allSitePage(filter: { path: { eq: $path } }) {
      edges {
        node {
          context {
            lang
            title
            description
            content {
              navigation {
                cta {
                  type
                  target
                  text
                }
                links {
                  type
                  target
                  text
                }
              }
              contact {
                form {
                  title
                  name
                  email
                  message
                  submit
                }
              }
              services {
                featured
                img
                title
                getintouch
                content
              }
              about {
                title
                content
              }
            }
          }
        }
      }
    }
    cloud: file(relativePath: { eq: "cloud.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    camera: file(relativePath: { eq: "camera.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    dog: file(relativePath: { eq: "dog.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    shipping: file(relativePath: { eq: "shipping.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    crayon: file(relativePath: { eq: "crayon.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    guide: file(relativePath: { eq: "guide.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lamb: file(relativePath: { eq: "lamb.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    money: file(relativePath: { eq: "money.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default DefaultTemplate
