import React, { SFC } from "react"

export const Footer: SFC = () => (
  <footer className="bg-gray-900 px-10 py-5">
    <div className="flex">
      <div className="w-full md:w-1/2">
        <span className="text-gray-500">&copy; 2019 ZapWerx</span>
      </div>
      <div className="w-full md:w-1/2 text-white text-right">
        <a
          className="px-2 hover:text-blue-300"
          href="https://ca.linkedin.com/company/zapwerx"
        >
          LinkedIn
        </a>
        <a
          className="px-2 hover:text-blue-300"
          href="https://twitter.com/zapwerx"
        >
          Twitter
        </a>
      </div>
    </div>
  </footer>
)
export default Footer
