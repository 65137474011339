import React, { SFC } from "react"
interface props {
  id: string
  title: string
}
const AboutUs: SFC<props> = ({ id, title, children }) => (
  <section id={id}>
    <header className="text-xl font-bold text-white mb-4">
      <span className="border-white border-b-2 px-1 py-2">{title}</span>
    </header>
    <p className="text-white">{children}</p>
  </section>
)

export default AboutUs
