import React, { SFC } from "react"
import ReCAPTCHA from "react-google-recaptcha";

interface props {
  data: {
    name: string
    email: string
    message: string
    submit: string
    "g-recaptcha-response": string
  }
  lang: "fr" | "en"
}
function encode(data: any) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export const ContactForm: SFC<props> = ({ data, lang }) => {
  const [state, setState] = React.useState({ submitted: false, "g-recaptcha-response": null })

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }
  const handleCaptchaChange = v => {
    setState({
      ...state,
      "g-recaptcha-response": v
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch(`/${lang}/`, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => {
        setState({
          submitted: true,
          "g-recaptcha-response": null
        })
      })
      .catch(error => alert(error))
  }

  return (
    <>
      {state.submitted ? (
        <div className="bg-green-700 px-2 py-5 text-white rounded font-bold">
          Message Sent!
        </div>
      ) : null}
      <form
        name="contact"
        method="post"
        action={`/${lang}/`}
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
        className="contact-form"
        data-netlify-recaptcha="true"
      >
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type="hidden" name="form-name" value="contact" />
        <p hidden>
          <label>
            Don’t fill this out:{" "}
            <input name="bot-field" onChange={handleChange} />
          </label>
        </p>

        <label htmlFor="name">{data.name}</label>
        <input type="text" required name="name" onChange={handleChange} />

        <label htmlFor="email">{data.email}</label>
        <input type="email" required name="email" onChange={handleChange} />

        <label htmlFor="message">{data.message}</label>
        <textarea
          name="message"
          className="h-auto"
          required
          style={{ minHeight: "128px" }}
          onChange={handleChange}
        />
        { state["g-recaptcha-response"] ? <button type="submit">{data.submit}</button> : <ReCAPTCHA className="captcha" name="g-recaptcha-response" onChange={handleCaptchaChange} sitekey="6LfOOdYUAAAAAGhIMB0WWC8VzyLHvr72z940jGtN" /> }
      </form>
    </>
  )
}

export default ContactForm
