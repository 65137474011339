import React, { SFC } from "react"
import GatsbyImage from "gatsby-image"

interface props {
  featured?: boolean
  title: string
  getintouch?: string
  img?: any
}
export const Service: SFC<props> = ({
  img,
  featured,
  title,
  getintouch,
  children,
}) => {
  if (featured) {
    return (
      <section className="w-full">
        <div className="bg-white m-5 flex-col md:flex-row flex">
          <div className="w-full md:w-1/2 lg:w-1/3 relative">
            <GatsbyImage fluid={img} imgStyle={{ objectFit: "contain" }} />
          </div>
          <div className="w-full md:w-1/2 lg:w-2/3 p-5 md:p-10">
            <header className="text-3xl font-bold text-blue-800 mb-4 leading-relaxed">
              {title}
            </header>
            <p className="text-gray-600" dangerouslySetInnerHTML={{ __html: children?.toString() || '' }} />
            <div>
              <a
                href="#contact"
                className="bg-blue-500 block text-center md:inline-block px-6 py-3 rounded mt-5 text-white hover:bg-blue-700"
              >
                {getintouch}
              </a>
            </div>
          </div>
        </div>
      </section>
    )
  } else {
    return (
      <section className="w-full sm:w-1/2 md:w-1/3">
        <div className="bg-white m-5">
          <div>
            {img ? (
              <GatsbyImage
                fluid={img}
                className="h-64"
                imgStyle={{ objectPosition: "center" }}
              />
            ) : null}
          </div>
          <div className="p-5 md:p-10">
            <header className="text-xl font-bold text-blue-800 mb-4 leading-relaxed">
              {title}
            </header>
            <div className="service--description" dangerouslySetInnerHTML={{__html: children?.toString() || ''}}>

            </div>
          </div>
        </div>
      </section>
                )
              }
            }
            
            export default Service
